@media (min-width: 768px) {
  .HLP-hero .HLP-hero-spa1 {
    max-width: 300px;
  }
  .HLP-hero .HLP-hero-spa3 {
    max-width: 310px;
  }
}

.HLP-Hero-pre-heading--bar-tiles,
.HLP-hero__left-aligned-tiles-wrapper-dark
  .HLP-hero__left-aligned-tiles-pre-heading {
  color: var(--text-main-on-dark);
}

.HLP-hero--bar-tiles {
  margin-top: var(--navbar-height);
}

.HLP-block {
  background: var(--colour-white);
}

.HLP-block .FooterCTA-heading {
  color: var(--text-main-on-dark);
}

.ActionCardTemplate-text > span {
  margin-left: calc(var(--base-unit) / 2);
  margin-right: calc(var(--base-unit) / 2);
}

.HLP-Hero-pre-heading--bar-tiles.HLP-Hero-pre-heading--bar-tiles {
  color: var(--colour-white);
}

.HLP-hero-im-dropdown__wrapper
  .HLP-hero-content
  .HLP-hero-im-dropdown
  .CTA-block-primary {
  min-width: 270px;
}

@media (min-width: 768px) {
  .HLP-hero-im-dropdown {
    grid-template-columns: repeat(2, minmax(215px, 335px)) 1fr;
  }
}
